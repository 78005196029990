import type { ActionReturn } from "svelte/action";

export function scrollIntoView(element: HTMLElement, enabled: boolean): ActionReturn<boolean> {
	function scroll(enabled: boolean): void {
		if (enabled) {
			element.scrollIntoView({ behavior: "smooth", block: "nearest" });
		}
	}

	scroll(enabled);

	return {
		update(enabled): void {
			scroll(enabled);
		},
	};
}
