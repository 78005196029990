export function slidesForCyclicGlider(slides: number[], slidesCount: number): number[] {
	return slides.map((slide) => {
		if (slide === 0) {
			return slidesCount;
		}
		if (slide === slidesCount + 1) {
			return 1;
		}
		if (slide < 0) {
			return slidesCount + slide;
		}
		return slide;
	});
}
