export function slugify(text: string): string {
	return text
		.toString() // Cast to string (optional)
		.normalize("NFKD") // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
		.toLowerCase() // Convert the string to lowercase letters
		.trim() // Remove whitespace from both sides of a string (optional)
		.replace(/\s+/gu, "-") // Replace spaces with -
		.replace(/[^\w-]+/gu, "") // Remove all non-word chars
		.replace(/_/gu, "-") // Replace _ with -
		.replace(/--+/gu, "-") // Replace multiple - with single -
		.replace(/-$/gu, ""); // Remove trailing -
}
