import type { ActionReturn } from "svelte/action";

export function lazyLoadVideo(
	video: HTMLVideoElement,
	{ enabled = true, autoplay = false }: { enabled?: boolean; autoplay?: boolean },
): ActionReturn {
	if (!enabled || (autoplay && !video.muted)) {
		return {};
	}

	let loaded = false;
	const observer = new IntersectionObserver((entries, observer) => {
		for (const { isIntersecting, target: video } of entries) {
			if (!loaded && isIntersecting && video instanceof HTMLVideoElement) {
				video.load();
				video.autoplay = autoplay;
				observer.unobserve(video);
				loaded = true;
			}
		}
	});

	observer.observe(video);

	return {
		destroy(): void {
			observer.disconnect();
		},
	};
}
