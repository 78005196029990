<script lang="ts">
	import spotify from "../assets/images/spotify.png?webp&h=32&imagetools";
	import applePodcast from "../assets/images/apple-podcast.png?webp&h=32&imagetools";
	import podbean from "../assets/images/podbean.png?webp&h=32&imagetools";
	import StaticPicture from "./Picture/StaticPicture.svelte";
	import type { Podcast } from "../../../core/schema/Podcast.js";
	import Link from "./Link.svelte";
	import { getGlobalSettings } from "../getGlobalSettings.js";
	import Content from "./Content.svelte";
	import { nbspify } from "../../../core/utils/nbspify.js";
	import { slugify } from "../../../core/utils/slugify";

	export let first: boolean;
	export let podcast: Podcast;

	const { TENANT_LOCALE } = getGlobalSettings();

	$: id = `${podcast.serialNumber}-${slugify(podcast.name[TENANT_LOCALE])}`;
</script>

<a
	class="border-gray group relative flex h-[20rem] grow basis-0 cursor-pointer flex-col gap-[1.625rem] border-y p-6 transition-all delay-500 duration-150 sm:h-[16.5rem] sm:flex-row {!first &&
		'[900px]:border-r-0 border-x'}"
	href="#{id}"
	target="_self"
>
	<div class="group-hover:bg-mint absolute inset-0 -z-10"></div>
	<span class="font-quicksand group-hover:text-tertiary text-gray text-[4.375rem] leading-[4rem] sm:text-end">
		{podcast.serialNumber}
	</span>
	<div class="flex h-full flex-col gap-2">
		<p class="text-primary md:text-md text-base font-extrabold xl:text-[1.5rem]">
			{nbspify(podcast.name[TENANT_LOCALE])}
		</p>
		<p class="text-2xs flex items-center gap-2 font-bold">
			<span>{nbspify(podcast.releaseDate)}</span>
			<span>–</span>
			<span>{nbspify(podcast.duration)}</span>
		</p>
		{#if podcast.description}
			<div class="line-clamp-3 max-w-[18.75rem] overflow-hidden text-ellipsis text-xs text-gray-400">
				<Content content={podcast.description[TENANT_LOCALE]} contentClass={false} />
			</div>
		{/if}
		<div class="mt-auto flex gap-2">
			{#if podcast.spotifyUrl}
				<Link href={podcast.spotifyUrl} target="_blank">
					<StaticPicture image={spotify} alt="Odkaz na spotify" width={32} height={32} />
				</Link>
			{/if}
			{#if podcast.applePodcastUrl}
				<Link href={podcast.applePodcastUrl} target="_blank">
					<StaticPicture image={applePodcast} alt="Odkaz na apple podcast" width={32} height={32} />
				</Link>
			{/if}
			{#if podcast.podBeanUrl}
				<Link href={podcast.podBeanUrl} target="_blank">
					<StaticPicture image={podbean} alt="Odkaz na podbean" width={32} height={32} />
				</Link>
			{/if}
		</div>
	</div>
</a>
